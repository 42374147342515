import React from "react";
import MorePortfolio from "../components/MorePortfolio";

const MorePortfolioPage = () => {
  return (
    <>
      <MorePortfolio />
    </>
  );
};

export default MorePortfolioPage;
