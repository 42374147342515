import React from "react";
import Freelancer from "../components/Freelancer";

const FreelancerPage = () => {
  return (
    <>
      <Freelancer />
    </>
  );
};

export default FreelancerPage;
