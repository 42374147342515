import React from "react";
import Portfolio from "../components/Portfolio";

const PortfolioPage = () => {
  return (
    <>
      <Portfolio />
    </>
  );
};

export default PortfolioPage;
